import Vue from 'vue'
import VueRouter from 'vue-router'

// General
const Home = () => import(/* webpackChunkName: "home-page" */ '../views/home')
const Faq = () => import(/* webpackChunkName: "faq-page" */ '../views/faq')
const LastMinute = () => import(/* webpackChunkName: "last-minute-page" */ '../views/lastMinute/index')
const LastMinuteMailConfirm = () => import(/* webpackChunkName: "last-minute-mail-confirm-page" */ '../views/lastMinute/mail-confirm')
const CartShowPage = () => import(/* webpackChunkName: "cart-show-page" */ '../views/cart/show')
// Legal
// const BookingTerms = () => import(/* webpackChunkName: "booking-terms-page" */ '../views/bookingTerms')
const Contact = () => import(/* webpackChunkName: "contact-page" */ '../views/contact')
const LegalIndex = () => import(/* webpackChunkName: "legal-index-page" */ '../views/legal/index')
const Imprint = () => import(/* webpackChunkName: "imprint-page" */ '../views/legal/imprint')
const Privacy = () => import(/* webpackChunkName: "privacy-page" */ '../views/legal/privacy')

// Booking
const BookingIndex = () => import(/* webpackChunkName: "booking-index-page" */ '../views/booking/index')
const BookingInfo = () => import(/* webpackChunkName: "booking-info-page" */ '../views/booking/info')
const BookingSearch = () => import(/* webpackChunkName: "booking-search-page" */ '../views/booking/search')
const BookingCart = () => import(/* webpackChunkName: "booking-cart-page" */ '../views/booking/cart')
const BookingCheckout = () => import(/* webpackChunkName: "booking-checkout-page" */ '../views/booking/checkout')
const BookingTerms = () => import(/* webpackChunkName: "booking-terms-page" */ '../views/bookingTerms')

// Bookings
const BookingsIndex = () => import(/* webpackChunkName: "bookings-index-page" */ '../views/bookings/index')
const BookingsRequestCancellation = () => import(/* webpackChunkName: "booking-info-page" */ '../views/bookings/requestCancellation')
const BookingsCancel = () => import(/* webpackChunkName: "bookings-cancel-page" */ '../views/bookings/cancel')
const BookingsCancelSuccess = () => import(/* webpackChunkName: "bookings-cancel-success-page" */ '../views/bookings/cancel-success')

// Payments
const PaymentsIndex = () => import(/* webpackChunkName: "payments-index-page" */ '../views/pay/index')
const PaymentsChangePaymentMethod = () => import(/* webpackChunkName: "payments-change-payment-method-page" */ '../views/pay/changePaymentMethod')
const PaymentsPayPalCapture = () => import(/* webpackChunkName: "payments-paypal-capture-page" */ '../views/pay/paypalCapture')

// Bikes
const BikesIndex = () => import(/* webpackChunkName: "bikes-index-page" */ '../views/bikes/index')
const BikesMusketier = () => import(/* webpackChunkName: "bikes-musketier-page" */ '../views/bikes/musketier')
const BikesRapid = () => import(/* webpackChunkName: "bikes-rapid-page" */ '../views/bikes/rapid')
const Bikes2cycle = () => import(/* webpackChunkName: "bikes-2cycle-page" */ '../views/bikes/2cycle')
const BikesCargoHybrid = () => import(/* webpackChunkName: "bikes-cargo-hybrid-page" */ '../views/bikes/cube-cargo.vue')
const BikesPackster70 = () => import(/* webpackChunkName: "bikes-packster-70-page" */ '../views/bikes/packster70.vue')

// Instructions
const InstructionsIndex = () => import(/* webpackChunkName: "instructions-index-page" */ '../views/instructions/index')
const InstructionsMusketierKoffer = () => import(/* webpackChunkName: "instructions-musketier-koffer-page" */ '../views/instructions/musketierKoffer')
const InstructionsMusketierPritsche = () => import(/* webpackChunkName: "instructions-musketier-pritsche-page" */ '../views/instructions/musketierPritsche')
const InstructionsRapidCargowanne = () => import(/* webpackChunkName: "instructions-rapid-cargowanne-page" */ '../views/instructions/rapidCargowanne')
const InstructionsCubeCargoHybrid = () => import(/* webpackChunkName: "instructions-cube-cargo-hybrid-page" */ '../views/instructions/cubeCargoHybrid')
const InstructionsPackster70 = () => import(/* webpackChunkName: "instructions-packster-70-page" */ '../views/instructions/packster70')


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/cart/:item',
        name: 'cart',
        component: CartShowPage
    },
    {
        path: '/faq',
        name: 'faq',
        component: Faq
    },
    {
        path: '/last-minute',
        name: 'last-minute',
        component: LastMinute
    },
    {
        path: '/last-minute-mail/confirm',
        name: 'last-minute-mail-confirm',
        component: LastMinuteMailConfirm
    },
    {
        path: '/booking-terms',
        name: 'booking-terms',
        component: BookingTerms
    },
    {
        path: '/contact',
        name: "contact",
        component: Contact
    },
    {
        path: "/bikes",
        name: "bikes",
        component: BikesIndex,
        redirect: {name: 'bikes.musketier'},
        children: [
            {
                path: 'musketier',
                name: 'bikes.musketier',
                component: BikesMusketier
            },
            {
                path: 'rapid',
                name: 'bikes.rapid',
                component: BikesRapid
            },
            {
                path: '2cycle',
                name: 'bikes.2cycle',
                component: Bikes2cycle
            },
            {
                path: 'cargo-hybrid',
                name: 'bikes.cargoHybrid',
                component: BikesCargoHybrid
            },
            {
                path: 'packster-70',
                name: 'bikes.packster70',
                component: BikesPackster70
            },
        ]
    },
    {
        path: "/instructions",
        name: "instructions",
        component: InstructionsIndex,
        redirect: {name: 'instructions.musketier'},
        children: [
            {
                path: 'musketier-koffer',
                name: 'instructions.musketierKoffer',
                component: InstructionsMusketierKoffer
            },
            {
                path: 'musketier-pritsche',
                name: 'instructions.musketierPritsche',
                component: InstructionsMusketierPritsche
            },
            {
                path: 'rapid-cargowanne',
                name: 'instructions.rapidCargowanne',
                component: InstructionsRapidCargowanne
            },
            {
                path: 'cube-cargo-hybrid',
                name: 'instructions.cubeCargoHybrid',
                component: InstructionsCubeCargoHybrid
            },
            {
                path: 'packster-70',
                name: 'instructions.packster70',
                component: InstructionsPackster70
            },
        ]
    },
    {
        path: "/bookings",
        name: "bookings",
        component: BookingsIndex,
        children: [
            {
                path: ':booking_id/request-cancellation',
                name: 'booking.requestCancellation',
                component: BookingsRequestCancellation
            },
            {
                path: ':booking_id/cancel',
                name: 'bookings.cancel',
                component: BookingsCancel
            },
            {
                path: ':booking_id/cancel/success',
                name: 'bookings.cancel.success',
                component: BookingsCancelSuccess
            },
        ]
    },
    {
        path: "/booking",
        name: "booking",
        component: BookingIndex,
        redirect: {name: 'booking.search'},
        children: [
            {
                path: 'info',
                name: 'booking.info',
                component: BookingInfo
            },
            {
                path: 'terms',
                name: 'booking.terms',
                component: BookingTerms
            },
            {
                path: 'search',
                name: 'booking.search',
                component: BookingSearch
            },
            {
                path: 'cart',
                name: 'booking.cart',
                component: BookingCart
            },
            {
                path: 'checkout/:payment_id',
                name: 'booking.checkout',
                component: BookingCheckout
            },
        ]
    },
    {
        path: "/legal",
        name: "legal",
        component: LegalIndex,
        redirect: {name: 'legal.imprint'},
        children: [
            {
                path: 'imprint',
                name: 'legal.imprint',
                component: Imprint
            },
            {
                path: 'privacy',
                name: 'legal.privacy',
                component: Privacy
            },
        ]
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 }
    },
})

export default router
