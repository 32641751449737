<template>
    <v-btn color="green darken-4"
           :large="large"
           :block="block"
           :to="to"
           :icon="icon"
           :loading="loading"
           :right="right"
           @click="click"
           dark
    >
        <slot />
    </v-btn>
</template>

<script>
export default {
    name: "green-button",

    props: {
        to: {
            type: Object | String,
            default: null
        },
        large: {
            type: Boolean,
            default: false
        },
        block: {
            type: Boolean,
            default: false
        },
        icon: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        right: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        click(payload) {
            this.$emit('click', payload);
        }
    }

}

</script>
