<template>
    <div>
        <v-app-bar
            app
            color="white"
            height="100"
        >
            <v-container>
                <v-row align="center">
                    <v-col lg="4" class="d-flex align-center">
                        <router-link :to="{name: 'home'}">
                            <v-img
                                alt="bigRad Logo"
                                contain
                                src="../../assets/logo.png"
                                transition="none"
                                width="200"
                            />
                        </router-link>
                    </v-col>

                    <v-col class="d-none d-md-flex" >
                        <v-btn text
                               :to="{name: 'home'}"
                               active-class="green--text text--darken-4"
                               exact>
                            Startseite
                        </v-btn>

                        <v-spacer></v-spacer>
                        <v-menu
                            open-on-hover
                            bottom
                            offset-y
                            min-width="200"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn text
                                       v-bind="attrs"
                                       v-on="on"
                                       active-class="green--text text--darken-4"
                                >
                                    Bikes
                                </v-btn>
                            </template>

                            <v-list dense>
                                <v-subheader>Transporter</v-subheader>
                                <v-list-item dense
                                             :to="{name: 'bikes.musketier'}"
                                             active-class="green--text text--darken-4"
                                >
                                    <v-list-item-title>
                                        Musketier
                                    </v-list-item-title>
                                </v-list-item>
                                <v-subheader>Long-John</v-subheader>
                                <v-list-item dense
                                             :to="{name: 'bikes.cargoHybrid'}"
                                             active-class="green--text text--darken-4"
                                >
                                    <v-list-item-title class="d-inline">
                                        Cargo Hybrid
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item dense
                                             :to="{name: 'bikes.packster70'}"
                                             active-class="green--text text--darken-4"
                                >
                                    <v-list-item-title>
                                        Packster 70
                                    </v-list-item-title>
                                    <v-badge content="Neu"
                                             color="green darken-4"
                                             left
                                    >
                                    </v-badge>
                                </v-list-item>
                                <v-subheader>Tandem</v-subheader>
                                <v-list-item dense
                                             :to="{name: 'bikes.2cycle'}"
                                             active-class="green--text text--darken-4"
                                >
                                    <v-list-item-title>
                                        2cycle
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>

                        <v-spacer></v-spacer>

                        <v-btn text
                               :to="{name: 'faq'}"
                               active-class="green--text text--darken-4"
                               exact
                        >
                            FAQ
                        </v-btn>
                    </v-col>

                    <v-col class="d-none d-md-flex">
                        <v-spacer></v-spacer>
                        <green-button large
                                      right
                                      :to="{name: 'booking.search'}"
                        >
                            Jetzt buchen
                        </green-button>
                    </v-col>

                    <v-col class="d-md-none pa-0" cols="auto">
                        <green-button icon
                                      large
                                      @click.stop="navOpen = !navOpen"
                        >
                            <v-icon>mdi-menu</v-icon>
                        </green-button>
                    </v-col>
                </v-row>
            </v-container>
        </v-app-bar>

        <v-navigation-drawer
            v-model="navOpen"
            fixed
            temporary
        >
            <router-link :to="{name: 'home'}">
                <v-img
                    alt="bigRad Logo"
                    contain
                    src="../../assets/logo.png"
                    transition="none"
                    width="200"
                    class="ma-auto mt-4 mb-10"
                />
            </router-link>

            <v-list
                nav
                dense
            >
                <v-list-item-group
                    active-class="green--text text--darken-4"
                >
                    <v-list-item :to="{name: 'home'}" exact-path>
                        <v-list-item-title>Startseite</v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="bikesOpen = !bikesOpen">
                        <v-list-item-title>Bikes</v-list-item-title>
                    </v-list-item>

                    <div v-if="bikesOpen"
                         class="pl-3"
                    >
                        <v-subheader>Transporter</v-subheader>
                        <v-list-item dense
                                     :to="{name: 'bikes.musketier'}"
                                     active-class="green--text text--darken-4"
                        >
                            <v-list-item-title>
                                Musketier
                            </v-list-item-title>
                        </v-list-item>
                        <v-subheader>Long-John</v-subheader>
                        <v-list-item dense
                                     :to="{name: 'bikes.cargoHybrid'}"
                                     active-class="green--text text--darken-4"
                        >
                            <v-list-item-title class="d-inline">
                                Cargo Hybrid
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item dense
                                     :to="{name: 'bikes.packster70'}"
                                     active-class="green--text text--darken-4"
                        >
                            <v-list-item-title>
                                Packster 70
                            </v-list-item-title>
                            <v-badge content="Neu"
                                     color="green darken-4"
                                     left
                            >
                            </v-badge>
                        </v-list-item>
                        <v-subheader>Tandem</v-subheader>
                        <v-list-item dense
                                     :to="{name: 'bikes.2cycle'}"
                                     active-class="green--text text--darken-4"
                        >
                            <v-list-item-title>
                                2cycle
                            </v-list-item-title>
                        </v-list-item>
                    </div>

                    <v-list-item :to="{name: 'faq'}">
                        <v-list-item-title>FAQ</v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
            <div class="pa-2 mt-5">
                <green-button large
                              block
                              :to="{name: 'booking.search'}"
                >
                    Jetzt buchen
                </green-button>
            </div>
        </v-navigation-drawer>

        <v-main :class="[{'mt-10': !withoutTopMargin}, 'mb-10']"
                style="min-height: 70vh"
        >
            <slot></slot>
        </v-main>

        <v-footer color="white" elevation="4" class="py-5">
            <v-container>
                <v-row>
                    <v-col cols="12" sm="4">
                        <div class="d-flex align-center">
                            <router-link :to="{name: 'home'}">
                                <v-img
                                    alt="bigRad Logo"
                                    contain
                                    src="../../assets/logo.png"
                                    width="200"
                                    style="max-width: 200px;"
                                    transition="none"
                                    class="mx-auto mx-sm-0"
                                />
                            </router-link>
                        </div>

                        <p class="mt-10 pr-sm-6">bigRad ist dein Verleiher für hochwertige moderne E-Lastenräder in München.
                            Ausleihe und Rückgabe sind rund um die Uhr möglich. Buchen und bezahlen erledigst du bequem
                            online.
                        </p>
                    </v-col>
                    <v-col cols="12" sm="8">
                       <v-row>
                           <v-col cols="12" sm="6">
                               <h4 class="display-1 mb-4">Die <strong class="green--text text--darken-4">Bikes</strong></h4>

                               <footer-menu-item :to="{name: 'bikes.musketier'}">
                                   Musketier
                               </footer-menu-item>

                               <footer-menu-item :to="{name: 'bikes.packster70'}">
                                   Packster 70
                               </footer-menu-item>

                               <footer-menu-item :to="{name: 'bikes.cargoHybrid'}">
                                   Cargo Hybrid
                               </footer-menu-item>

                               <footer-menu-item :to="{name: 'bikes.2cycle'}">
                                   2cycle
                               </footer-menu-item>
                           </v-col>
<!--                           <v-col cols="12" sm="6" md="4">-->
<!--                               <h4 class="display-1 mb-4">Deine <strong class="green&#45;&#45;text text&#45;&#45;darken-4">Fahrt</strong></h4>-->

<!--                               <footer-menu-item :to="{name: 'booking.info'}">-->
<!--                                   Buchung-->
<!--                               </footer-menu-item>-->

<!--                               <footer-menu-item :to="{name: 'pickup'}">-->
<!--                                   Abholung-->
<!--                               </footer-menu-item>-->

<!--                               <footer-menu-item :to="{name: 'ride'}">-->
<!--                                   Fahrt & Pause-->
<!--                               </footer-menu-item>-->

<!--                               <footer-menu-item :to="{name: 'return'}">-->
<!--                                   Rückgabe-->
<!--                               </footer-menu-item>-->
<!--                           </v-col>-->
                           <v-col cols="12" sm="6">
                               <h4 class="display-1 mb-4">Weitere <strong class="green--text text--darken-4">Infos</strong></h4>

<!--                               <footer-menu-item :to="{name: 'pricing'}">-->
<!--                                   Preisliste-->
<!--                               </footer-menu-item>-->

<!--                               <footer-menu-item :to="{name: 'booking-terms'}">-->
<!--                                   Buchungsbedingungen-->
<!--                               </footer-menu-item>-->

                               <footer-menu-item :to="{name: 'contact'}">
                                   Kontakt
                               </footer-menu-item>

                               <footer-menu-item :to="{name: 'legal.privacy'}">
                                   Datenschutz
                               </footer-menu-item>

                               <footer-menu-item :to="{name: 'legal.imprint'}">
                                   Impressum
                               </footer-menu-item>
                           </v-col>
                       </v-row>
                    </v-col>
                </v-row>

                <v-row class="mt-0">
                </v-row>
            </v-container>
        </v-footer>
    </div>
</template>

<script>
import FooterMenuItem from "@/components/cms/footer/FooterMenuItem";
import GreenButton from "@/components/cms/elements/GreenButton";
export default {
    components: {GreenButton, FooterMenuItem},

    props: {
        withoutTopMargin: {
            type: Boolean,
            default: false
        }
    },

    data: () => ({
        navOpen: false,
        bikesOpen: false
    }),

    mounted() {
        window.scrollTo({ top: 0 });
    }
}
</script>
