<template>
    <v-list-item :to="to"
                 style="min-height: 34px"
                 exact
                 active-class="green--text text--darken-4"
    >
        <v-list-item-icon class="mr-1 my-auto">
            <v-icon>mdi-chevron-right</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="py-1">
            <v-list-item-title>
                <slot></slot>
            </v-list-item-title>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
export default {
    name: "footer-menu-item",

    props: {
        to: {
            type: String|Object,
            required: true
        }
    }
}
</script>
